
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },

  setup () {
    

    return {}
  },
})
