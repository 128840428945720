<template>
  <BaseCard>
    <BaseCardHeader :loading="loading">
      Requests
    </BaseCardHeader>

    <div class="p-4" />
  </BaseCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },

  setup () {
    

    return {}
  },
})
</script>
