<template>
  <div
    v-if="singleDeletingEntry"
    class="flex h-8 w-full border-4 border-white rounded-full"
  >
    <EntryPill
      :content="entries[0].textContent"
      :type="entries[0].type"
      :status="entries[0].status"
      :is-locum="entries[0].isLocum"
      :is-with-absence="entries[0].isWithAbsence"
      :with-absence="entries[0].withAbsence"
      clickable
      size="multi"
      tooltip
      @clicked="$emit('clicked', entries[0].entry_id ?? null)"
    />
    <EntryPill 
      content="+"
      type="off"
      :status="null"
      clickable
      size="multi-sm"
      @clicked="$emit('clicked', null)"
    />
  </div>

  <EntryPill
    v-else-if="entries.length === 1"
    :content="entries[0].textContent"
    :type="entries[0].type"
    :status="entries[0].status"
    :is-locum="entries[0].isLocum"
    :is-with-absence="entries[0].isWithAbsence"
    :with-absence="entries[0].withAbsence"
    size="full"
    clickable
    @clicked="$emit('clicked', entries[0].entry_id ?? null)"
  />

  <div
    v-else
    class="flex h-8 w-full border-4 border-white rounded-full"
  >
    <EntryPill
      v-for="(entryPill, idx) in entries"
      :key="idx"
      :type="entryPill.type"
      :content="entryPill.textContent"
      :status="entryPill.status"
      :is-locum="entryPill.isLocum"
      :is-with-absence="entryPill.isWithAbsence"
      :with-absence="entryPill.withAbsence"
      clickable
      size="multi"
      tooltip
      @clicked="$emit('clicked', entryPill.entry_id)"
    />
  </div>
</template>

<script lang="ts">
import { EntryPillData, EntryStatus } from '@/types/roster'
import { computed, defineComponent, PropType } from 'vue'
import EntryPill from './EntryPill.vue'

export default defineComponent({
  components: {
    EntryPill,
  },

  props: {
    entries: {
      type: Array as PropType<EntryPillData[]>,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['clicked'],

  setup (props) {
    
    const singleDeletingEntry = computed(() => {
      return props.entries.length === 1 && props.entries[0].status === EntryStatus.DELETING
    })

    return {
      singleDeletingEntry,
    }
  },
})
</script>
