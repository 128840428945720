
import { computed, defineComponent, ref, toRefs, watch } from 'vue'
import moment from 'moment'
import pick from 'lodash/pick'

// Types
import { EntryStatus, Entry, DateBasics } from '@/types/roster'

// Hooks
import { useRoute, useRouter } from 'vue-router'
import useToasts from '@/hooks/useToasts'
import useSingleUserRota from '@/hooks/useSingleUserRota'
import useSingleUserRotaTable from '@/hooks/useSingleUserRotaTable'
import usePeriodStartDate from '@/hooks/usePeriodStartDate'

// Components
import InfoIcon from '@/components/icons/toast/InfoIcon.vue'
import DataTable from '@/components/base/table/DataTable.vue'
import EntryMultiPill from '@/components/rota/EntryMultiPill.vue'
import EntryCreateForm from '@/components/rota/form/EntryCreateForm.vue'
import EntryApprovalForm from '@/components/rota/form/EntryApprovalForm.vue'
import EntryDeletingForm from '@/components/rota/form/EntryDeletingForm.vue'
import UserRotaAdminKey from '@/components/rota/user/UserRotaAdminKey.vue'

export default defineComponent({
  components: {
    InfoIcon,
    DataTable,
    EntryMultiPill,
    EntryCreateForm,
    EntryApprovalForm,
    EntryDeletingForm,
    UserRotaAdminKey,
  },

  props: {
    userId: {
      type: String,
      required: true,
    },
    firstName: {
      type: String,
      required: true,
    },
    fullName: {
      type: String,
      required: true,
    },
    loadingUser: {
      type: Boolean,
      default: true,
    },
  },

  setup (props) {
    const route = useRoute()
    const router = useRouter()
    const { warningToast } = useToasts()

    const title = computed(() => {
      if (props.loadingUser || !props.firstName) return '...'

      return `${props.firstName}'s Rota`
    })

    /**
     * Rota
     */
    const { userId } = toRefs(props)

    const startDateString = computed(() => {
      if (
        route.query?.start_date && 
        moment(route.query.start_date.toString()).isValid()
      ) {
        return route.query.start_date.toString()
      }
      return moment().format('YYYY-MM-DD')
    })

    const endDateString = computed(() => {
      return moment(startDateString.value).add(4, 'weeks').format('YYYY-MM-DD')
    })

    const { 
      rota, 
      loadingRota, 
      updateRoster,
    } = useSingleUserRota(userId, startDateString, endDateString, 1)
    const { 
      rows, 
      columns,
    } = useSingleUserRotaTable(rota, loadingRota, true)

    /**
     * Key
     */
    const keyIsVisible = ref(false)

    const setKeyVisibility = (val: boolean) => {
      keyIsVisible.value = val
    }

    /**
     * Filters
     */
    const {
      startDate: inputMin,
    } = usePeriodStartDate()

    const dateInputValue = ref(startDateString.value)

    const startingYear = computed(() => moment(inputMin.value).year() ?? moment().year())
    const numberOfYears = computed(() => moment(inputMin.value).diff(moment(), 'years') + 1)

    const updateStartDate = (val: string) => {
      const newDate = moment(val)

      if (newDate.isBefore(inputMin.value)) {
        val = moment().format('YYYY-MM-DD')
        warningToast(`'From' date cannot be before ${moment(inputMin.value).format('DD MMM YYYY')}.`, 'Date Range')
        return
      }

      const max = moment().add(11, 'months')
      if (newDate.isAfter(max)) {
        val = moment().format('YYYY-MM-DD')
        warningToast(`'From' date cannot be after ${max.format('DD MMM YYYY')}.`, 'Date Range')
        return
      }

      router.replace({
        query: {
          start_date: val,
        },
      })
    }

    watch(dateInputValue, () => {
      updateStartDate(dateInputValue.value)
    })

    const showBackToToday = computed(() => startDateString.value !== moment().format('YYYY-MM-DD'))

    const goToToday = () => {
      dateInputValue.value = moment().format('YYYY-MM-DD')
    }

    /**
     * Entry Management
     */
    const modalIsVisible = ref(false)
    const action = ref('')
    const entryDate = ref<DateBasics | null>(null)
    const existingEntry = ref<Entry | null>(null)
    const approvingEntry = ref<Entry | null>(null)
    const deletingEntry = ref<Entry | null>(null)


    const hideModal = () => {
      modalIsVisible.value = false
    }

    const pillClickedHandler = (date_id: number, entry_id: number | null) => {
      modalIsVisible.value = true

      const rosterDate = rota.value.filter(i => i.id === date_id)[0]
      entryDate.value = pick(rosterDate, ['id', 'date', 'day_id', 'day'])
      const { entries } = rosterDate;

      const isMultiEntries = entries.length > 1
      const isSingleEntry = entries.length === 1

      if (!isSingleEntry && !isMultiEntries) { // create draft
        action.value = 'creating'
        existingEntry.value = null
        return
      }

      if (isSingleEntry && !entry_id) { // create draft (with existing 'deleting')
        action.value = 'creating'
        existingEntry.value = entries[0]
        return
      }

      if (isSingleEntry) {
        const selected = entries[0]

        switch (selected.status) {
          case EntryStatus.DRAFT: // approve/delete
            action.value = 'approving'
            approvingEntry.value = selected
            break
          case EntryStatus.DELETING: // approve/restore
            action.value = 'deleting'
            deletingEntry.value = selected
            break
          case EntryStatus.APPROVED: // replace/delete
            action.value = 'creating'
            existingEntry.value = selected
            break
          default:
            return
        }
      } else if (isMultiEntries) {
        const selected = entries[0].entry_id === entry_id ? entries[0] : entries[1]
        const other = entries[0].entry_id === entry_id ? entries[1] : entries[0]
        
        switch (selected.status) {
          case EntryStatus.DRAFT: // approve (with autodelete)/delete 
            action.value = 'approving'
            approvingEntry.value = selected
            deletingEntry.value = other
            break
          case EntryStatus.DELETING:
            action.value = 'deleting' // no restore
            deletingEntry.value = selected
            existingEntry.value = other
            break
          default:
            return
        }
      }
    }

    const resetEntryManagementState = () => {
      action.value = ''
      entryDate.value = null
      existingEntry.value = null
      approvingEntry.value = null
      deletingEntry.value = null
    }

    const managementSuccess = (date_id: number, newEntries: Entry[]) => {
      updateRoster(date_id, newEntries)
      resetEntryManagementState()
      hideModal()
    }

    const managementCancelled = () => {
      resetEntryManagementState()
      hideModal()
    }

    return {
      title,
      // Rota
      loadingRota,
      rota,
      rows,
      columns,
      // Key
      keyIsVisible,
      setKeyVisibility,
      // Filters
      dateInputValue,
      startingYear,
      numberOfYears,
      updateStartDate,
      goToToday,
      showBackToToday,
      // Entry Management
      pillClickedHandler,
      modalIsVisible,
      hideModal,
      action,
      entryDate,
      existingEntry,
      approvingEntry,
      deletingEntry,
      managementSuccess,
      managementCancelled,
    }
  },
})
