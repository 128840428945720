<template>
  <form
    class="px-4 md:px-10 py-4"
    @submit="onSubmit"
  >
    <header class="flex items-end mb-6 border-b-4 border-gray-300">
      <span class="flex w-10 h-10 md:w-12 md:h-12">
        <ProfileIconThick />
      </span>
      <h2 class="text-xl md:text-3xl font-bold ml-3 mb-1">
        User Role Change
      </h2>
    </header>
    <h2 class="text-xl md:w-3/4">
      Change <span class="font-bold">{{ userName }}</span>'s role from <span class="font-bold text-blue-medium">{{ currentRole }}</span>, to:
    </h2>

    <fieldset
      class="py-3"
      :disabled="submitting"
    >
      <VSelectInput
        :options="roleOptions"
        name="role"
        label=""
        theme="filter"
      />
    </fieldset>

    <BaseErrorMessage
      v-if="submissionError"
      extend-wrapper-classes="mb-3 md:mb-6"
    >
      {{ submissionError }}
    </BaseErrorMessage>
    <div class="flex flex-col md:flex-row justify-end items-center gap-3">
      <BaseButton
        size="md"
        @click="$emit('cancel')"
        :disabled="submitting"
      >
        Cancel
      </BaseButton>
      <BaseButton
        size="md"
        theme="success"
        type="submit"
        :working="submitting"
      >
        Confirm
      </BaseButton>
    </div>
  </form>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import UsersAPI from '@/apis/rota-architect/users'
import parseErrorMap from '@/utils/parseErrorMap'
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter'

// Types
import { Role } from '@/types/auth'

// Form
import { useForm } from 'vee-validate'
import * as yup from 'yup'

// Components
import ProfileIconThick from '@/components/icons/ProfileIconThick.vue'

const allRoleOptions = [
  { value: 'staff', label: 'Staff' },
  { value: 'admin', label: 'Admin' },
  { value: 'desk', label: 'Desk' },
]

export default defineComponent({
  components: {
    ProfileIconThick,
  },

  props: {
    userName: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    userRole: {
      type: String as PropType<Role>,
      validator: (val: string) => ['staff', 'admin', 'desk'].includes(val),
      required: true,
    },
  },

  emits: ['cancel', 'success'],

  setup (props, ctx) {
    const currentRole = computed(() => {
      return capitalizeFirstLetter(props.userRole)
    })

    /**
     * Form
     */
    const roleOptions = computed(() => allRoleOptions.filter(opt => opt.value !== props.userRole))

    const roleSchema: yup.ObjectSchema<{ 
       role: string; 
      }> = yup.object({
      role: yup
        .string()
        .required('Please select a role.'),
    }).defined()

      const { handleSubmit, errors } = useForm({
      validationSchema:  roleSchema,
    })

    const submitting = ref(false)
    const submissionError = ref('')

    const onSubmit = handleSubmit((values) => {
      if (submitting.value) return

      submitting.value = true
      submissionError.value = ''

      UsersAPI.updateRole(props.userId, { role: values.role })
        .then(() => {
          ctx.emit('success')
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data)
        })
        .finally(() => {
          submitting.value = false
        })
    })


    return {
      currentRole,
      roleOptions,
      formErrors: errors,
      onSubmit,
      submitting,
      submissionError,
    }
  },
})
</script>
