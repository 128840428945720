<template>
  <div class="mb-2">
    <slot name="header" />
  </div>
  <div class="grid-container min-h-70vh">
    <section class="item-rota">
      <slot name="rota" />
    </section>
    <section class="item-preferences">
      <slot name="preferences" />
    </section>
    <section class="item-details">
      <slot name="details" />
    </section>
    <section class="item-requests">
      <slot name="requests" />
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    

    return {}
  },
})
</script>

<style lang="scss" scoped>
.item-rota {
  grid-area: rota;
}

.item-preferences {
  grid-area: preferences;
}

.item-details {
  grid-area: details;
}

.item-requests {
  grid-area: requests;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 0.5rem;
  grid-template-areas:
    "rota"
    "details"
    "preferences"
    "requests";

  @screen md {
    grid-template-columns: 2fr 1fr;
    column-gap: 0.5rem;
    grid-template-areas:
      "rota rota"
      "preferences ."
      "details ."
      "requests .";
  }

  @screen lg {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "rota rota preferences"
      "details details requests";
  }

  @screen xxl {
    grid-template-columns: 4fr 2fr 1fr 3fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    row-gap: 0.5rem;
    grid-template-areas:
      "rota preferences preferences details"
      "rota preferences preferences details"
      "rota preferences preferences details"
      "rota preferences preferences details"
      "rota requests requests details"
      "rota requests requests .";
  }
}
</style>