
import { defineComponent, toRefs, watchEffect, ref, computed } from 'vue'
import useToasts from '@/hooks/useToasts'
import useRole from '@/hooks/useRole'
import UsersAPI from '@/apis/rota-architect/users'
import parseErrorMap from '@/utils/parseErrorMap'
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter'
import moment from 'moment'

// Store
import { useStore } from 'vuex'
import { GetterTypes as UserGetters } from '@/store/user/getters'

// Types
import { UserPreferences, UserSuccessResponse } from '@/types/users'

// Components
import StaffViewDashboard from '@/components/staff/layouts/StaffViewDashboard.vue'
import UserRotaAdmin from '@/components/rota/user/UserRotaAdmin.vue'
import StaffPreferences from '@/components/staff/StaffPreferences.vue'
import StaffDetails from '@/components/staff/StaffDetails.vue'
import StaffRequests from '@/components/staff/StaffRequests.vue'
import StaffRoleChange from '@/components/staff/StaffRoleChange.vue'

export default defineComponent({
  components: {
    StaffViewDashboard,
    UserRotaAdmin,
    StaffPreferences,
    StaffDetails,
    StaffRequests,
    StaffRoleChange,
  },

  props: {
    userId: { // from vue-router
      type: String,
      required: true,
    },
  },

  setup (props) {
    const store = useStore()
    const dispatch = useToasts()
    const role = useRole()

    const { userId } = toRefs(props)
    const user = ref<UserSuccessResponse & UserPreferences | null>(null)
    const loadingUser = ref(true)

    const fetchUser = () => {
      UsersAPI.show(userId.value)
        .then((res) => {
          user.value = res.data
        })
        .catch((err) => {
          dispatch.errorToast(parseErrorMap(err.response.data))
        })
        .finally(() => {
          loadingUser.value = false
        })
    }
    
    watchEffect(() => {
      fetchUser()
    })

    const rosterable = computed(() => {
      if (loadingUser.value || !user.value) return true

      return user.value.rosterable
    })

    const userName = computed(() => {
      if (loadingUser.value || !user.value) return ''

      return  user.value.name
    })

    const userFirstName = computed(() => {
      if (loadingUser.value || !user.value) return ''

      return  user.value.first_name
    })

    // Page data to display
    const pageHeading = computed(() => {
      if (loadingUser.value || !user.value) return '...'

      return `Viewing - ${user.value.name}`
    })

    const detailsArray = computed(() => {
      if (loadingUser.value || !user.value) return []

      const rosterables = user.value.sub_grade ? [
        { label: 'Grade', value: user.value.sub_grade ?? 'n/a' },
        { label: 'Primary Rota', value: user.value.primary_rota?.label ?? 'n/a' },
        { label: 'Contract', value: `${user.value.contract?.slug.toUpperCase()} ${user.value.contract_value ?? ''}`},
      ] : []

      return [
        { label: 'Name', value: user.value?.name },
        { label: 'DOB', value: moment(user.value.dob).format('DD/MM/YYYY') },
        { label: 'Phone', value: user.value.phone },
        { label: 'Email', value: user.value.email },
        ...rosterables,
      ]
    })

    const entitlementArray = computed(() => {
      if (loadingUser.value || !user.value || !user.value.sub_grade) return []

      return [
        { label: 'Annual Leave', value: user.value?.annual_leave },
        { label: 'Study Leave', value: user.value?.study_leave },
        { label: 'Parental Leave', value: user.value?.parental_leave },
      ]
    })

    const preferred = computed(() => {
      if (loadingUser.value || !user.value) return []
      return user.value.preferred
    })

    const unavailable = computed(() => {
      if (loadingUser.value || !user.value) return []
      return user.value.unavailable
    })

    // Invite logic
    const inviting = ref(false)

    const inviteUser = () => {
      if (inviting.value) return
      inviting.value = true

      UsersAPI.inviteUser(userId.value)
        .then(() => {
          fetchUser()
          dispatch.successToast('Invite sent successfully')
        })
        .catch((err) => {
          dispatch.errorToast(parseErrorMap(err.response.data))
        })
        .finally(() => {
          inviting.value = false
        })
    }

    const invitable = computed(() => {
      if (loadingUser.value || !user.value || role.isStaff.value) return false

      return user.value.invitable
    })

    const reinvitable = computed(() => {
      if (loadingUser.value || !user.value || role.isStaff.value) return false

      return (user.value.active_invites && !!user.value.active_invites.length)
    })

    const adminArray = computed(() => {
      if (loadingUser.value || !user.value || role.isStaff.value) return []

      let registeredValue = `${user.value.registered}`

      // check if active invite
      if (user.value.active_invites && user.value.active_invites.length) {
        const expiresAt = user.value.active_invites[user.value.active_invites.length - 1].expires_at
        registeredValue = `Invited (expires ${moment(expiresAt).fromNow()})`
      }

      return [
        { label: 'Registered', value: registeredValue },
        { 
          label: 'Role', 
          value: capitalizeFirstLetter(user.value?.role), 
          emitUpdateRole: 
            role.isSuper.value 
            && +user.value.id !== +store.getters[UserGetters.USER_ID],
        },
      ]
    })

    // Role update controls for Super admin
    const modalIsVisible = ref(false)

    const showModal = () => {
      if (!role.isSuper.value) return

      modalIsVisible.value = true
    }

    const hideModal = () => {
      modalIsVisible.value = false
    }

    const roleChangeDetails = computed(() => {
      if (loadingUser.value || !user.value || !role.isSuper.value) return null

      return {
        id: user.value.id,
        name: user.value.name,
        userRole: user.value.role,
      }
    })

    const roleChangeSuccess = () => {
      fetchUser()
      hideModal()
      dispatch.successToast('Role changed successfully')
    }

    return {
      pageHeading,
      userName,
      userFirstName,
      rosterable,
      preferred,
      unavailable,
      detailsArray,
      entitlementArray,
      adminArray,
      inviting,
      invitable,
      reinvitable,
      inviteUser,
      loadingUser,
      showModal,
      hideModal,
      modalIsVisible,
      roleChangeDetails,
      roleChangeSuccess,
    }
  },
})
