
import { EntryPillData, EntryStatus } from '@/types/roster'
import { computed, defineComponent, PropType } from 'vue'
import EntryPill from './EntryPill.vue'

export default defineComponent({
  components: {
    EntryPill,
  },

  props: {
    entries: {
      type: Array as PropType<EntryPillData[]>,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['clicked'],

  setup (props) {
    
    const singleDeletingEntry = computed(() => {
      return props.entries.length === 1 && props.entries[0].status === EntryStatus.DELETING
    })

    return {
      singleDeletingEntry,
    }
  },
})
