
import { computed, defineComponent, PropType, ref } from 'vue'
import UsersAPI from '@/apis/rota-architect/users'
import parseErrorMap from '@/utils/parseErrorMap'
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter'

// Types
import { Role } from '@/types/auth'

// Form
import { useForm } from 'vee-validate'
import * as yup from 'yup'

// Components
import ProfileIconThick from '@/components/icons/ProfileIconThick.vue'

const allRoleOptions = [
  { value: 'staff', label: 'Staff' },
  { value: 'admin', label: 'Admin' },
  { value: 'desk', label: 'Desk' },
]

export default defineComponent({
  components: {
    ProfileIconThick,
  },

  props: {
    userName: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    userRole: {
      type: String as PropType<Role>,
      validator: (val: string) => ['staff', 'admin', 'desk'].includes(val),
      required: true,
    },
  },

  emits: ['cancel', 'success'],

  setup (props, ctx) {
    const currentRole = computed(() => {
      return capitalizeFirstLetter(props.userRole)
    })

    /**
     * Form
     */
    const roleOptions = computed(() => allRoleOptions.filter(opt => opt.value !== props.userRole))

    const roleSchema: yup.ObjectSchema<{ 
       role: string; 
      }> = yup.object({
      role: yup
        .string()
        .required('Please select a role.'),
    }).defined()

      const { handleSubmit, errors } = useForm({
      validationSchema:  roleSchema,
    })

    const submitting = ref(false)
    const submissionError = ref('')

    const onSubmit = handleSubmit((values) => {
      if (submitting.value) return

      submitting.value = true
      submissionError.value = ''

      UsersAPI.updateRole(props.userId, { role: values.role })
        .then(() => {
          ctx.emit('success')
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data)
        })
        .finally(() => {
          submitting.value = false
        })
    })


    return {
      currentRole,
      roleOptions,
      formErrors: errors,
      onSubmit,
      submitting,
      submissionError,
    }
  },
})
