
import { defineComponent } from 'vue'
import DeletingStatusIcon from '@/components/icons/rota/DeletingStatusIcon.vue'
import DraftStatusIcon from '@/components/icons/rota/DraftStatusIcon.vue'
import LocumIcon from '@/components/icons/rota/LocumIcon.vue'

export default defineComponent({
  components: {
    DeletingStatusIcon,
    DraftStatusIcon,
    LocumIcon,
  },

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['leaving'],

  setup () {
    

    return {}
  },
})
